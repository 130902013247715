import {createRouter, createWebHistory} from 'vue-router'
import WzCaseForm from "../views/WzCaseForm";
import NotFound from "../views/errors/404";
import WzQuestionnaire from "../views/WzQuestionnaire";
import Success from "@/views/Success.vue";


const routes = [
    {
        path: '/:case_guid',
        name: 'WzCaseForm',
        component: WzCaseForm
    },
    {
        path: '/WzCaseForm/:case_guid/questionnaire/:wz_type_no',
        name: 'WzQuestionnaire',
        component: WzQuestionnaire
    },
    {
        path: "/:catchAll(.*)",
        name: 'NotFound',
        component: NotFound
    },
    {
        path: "/success",
        name: 'Success',
        component: Success
    }
]

const router = createRouter({
    mode: "history",
    history: createWebHistory(),
    routes
})

export default router
