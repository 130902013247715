import {createApp} from 'vue'
import App from "./App";
import i18n from "@/plugins/i18n";
import store from './store'


import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom.css";
import "animate.css";

import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;

import router from './router'

window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js').default;
require('bootstrap');

/** Create Vue application */
const app = createApp(App);
app.config.productionTip = false;

app.use(router)
    .use(VueAxios, axios)
    .use(i18n)
    .use(store);

/** Import all components */
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import Loader from "@/components/Loader";
import NoWzCaseFound from "@/components/WzCaseForm/NoWzCaseFound";
import Wz1Form from "@/components/WzCaseForm/Wz1/Wz1Form";
import Wz2Form from "@/components/WzCaseForm/Wz2/Wz2Form";
import Error from "@/components/Error";

import WzQuestionnaire from "@/views/WzQuestionnaire";
import Question from "@/components/WzQuestionnaire/Question";

import ServiceOrderData from "@/components/WzCaseForm/ServiceOrderData";
import DecisionTimeCounter from "@/components/WzCaseForm/DecisionTimeCounter";
import DamagesGallery from "@/components/WzCaseForm/DamagesGallery";
import Wz1Details from "@/components/WzCaseForm/Wz1/Wz1Details";
import Wz2Details from "@/components/WzCaseForm/Wz2/Wz2Details";

import StepOneGeneral from "@/components/WzCaseForm/Wz1/StepOneGeneral";
import StepOne70_77 from "@/components/WzCaseForm/Wz1/StepOne70_77";
import StepOne86_87 from "@/components/WzCaseForm/Wz1/StepOne86_87";
import StepTwo from "@/components/WzCaseForm/Wz1/StepTwo";
import StepThree from "@/components/WzCaseForm/Wz1/StepThree";
import StepFour from "@/components/WzCaseForm/Wz1/StepFour";
import StepFive from "@/components/WzCaseForm/Wz1/StepFive";

import ProcessLoader from "@/components/ProcessLoader";
import PaymentRejected from "./components/WzCaseForm/Payment/PaymentRejected";
import PaymentFinished from "./components/WzCaseForm/Payment/PaymentFinished";
import PaymentInProgress from "./components/WzCaseForm/Payment/PaymentInProgress";
import Phone from "@/components/Icons/Phone";
import Email from "@/components/Icons/Email";
import Globe from "@/components/Icons/Globe";
import Check from "@/components/Icons/Check";
import PromoBox from "@/components/WzCaseForm/PromoBox.vue";
import ConfirmationModal from "@/components/WzCaseForm/ConfirmationModal.vue";
import ServiceOrderDataNew from "@/components/WzCaseForm/Wz2/ServiceOrderDataNew.vue";
import FileUpload from "@/components/FileUpload.vue";
import DecisionTimeCounterNewStyle from "@/components/WzCaseForm/DecisionTimeCounterNewStyle.vue";
import Success from "@/views/Success.vue";

app.component('app-header', Header)
app.component('app-footer', Footer)

app.component('app-loader', Loader)
app.component('app-no-wz-case-found', NoWzCaseFound)
app.component('app-wz1-form', Wz1Form)
app.component('app-wz2-form', Wz2Form)
app.component('app-file_upload', FileUpload)
app.component('app-error', Error)

app.component('app-wz-questionnaire', WzQuestionnaire)
app.component('app-wz-question', Question)

app.component('app-service-order-data', ServiceOrderData)
app.component('app-service-order-data-new', ServiceOrderDataNew)
app.component('app-decision-time-counter', DecisionTimeCounter)
app.component('app-decision-time-counter-new', DecisionTimeCounterNewStyle)
app.component('app-damages-gallery', DamagesGallery)
app.component('app-promo-box', PromoBox)
app.component('app-confirmation-modal', ConfirmationModal)
app.component('success', Success)

app.component('app-wz1-details', Wz1Details)
app.component('app-wz2-details', Wz2Details)
app.component('app-wz1-step-one-general', StepOneGeneral)
app.component('app-wz1-step-one-70-77', StepOne70_77)
app.component('app-wz1-step-one-86-87', StepOne86_87)
app.component('app-wz1-step-two', StepTwo)
app.component('app-wz1-step-three', StepThree)
app.component('app-wz1-step-four', StepFour)
app.component('app-wz1-step-five', StepFive)
app.component('app-process-loader', ProcessLoader)

app.component('app-payment-rejected', PaymentRejected)
app.component('app-payment-finished', PaymentFinished)
app.component('app-payment-in-progress', PaymentInProgress)


app.component('app-icon-phone', Phone)
app.component('app-icon-email', Email)
app.component('app-icon-globe', Globe)
app.component('app-icon-check', Check)

/** Mount Vue application */
app.mount('#app')