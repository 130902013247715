<template>

  <div class="thanks">
    {{ $t('wz_case_form.wz2_form.success.thanks') }}
  </div>
  <div class="for-sent">
    {{ $t('wz_case_form.wz2_form.success.for_sent') }}
  </div>
  <div class="answer">
    {{ $t('wz_case_form.wz2_form.success.answer1') }}
    <span class="answer-bold">{{ $t('wz_case_form.wz2_form.success.answer2') }}</span>
    {{ $t('wz_case_form.wz2_form.success.answer3') }}
    <span class="answer-bold">{{ $t('wz_case_form.wz2_form.success.answer4') }}</span>
  </div>
  <div class="answer">
    {{ $t('wz_case_form.wz2_form.success.check') }}
  </div>
  <div class="check-button">
    {{ $t('wz_case_form.wz2_form.success.check_button') }}
  </div>

</template>

<script>

export default {
  name: "Success.vue",
  props: {
    message: {
      type: String,
      default: "default",
    }
  }
}
</script>

<style scoped>
  .thanks {
    margin-top: 130px;
    margin-bottom: -10px;
    width: 100%;
    color: #23AE84;
    font-family: Mulish;
    font-size: 48px;
    text-align: center;
    font-weight: bold;
  }
  .for-sent {
    margin-top: 0;
    width: 100%;
    color: #2A4A40;
    font-family: Mulish;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
  .answer {
    margin-top: 35px;
    width: 90%;
    margin-left: 5%;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    color: #444444;
    margin-bottom: 20px;
  }
  .answer-bold {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    text-align: center;
    color: #444444;
  }
  .check-button {
    width: 251px;
    height: 42px;
    padding: 11px 30px 11px 30px;
    border-radius: 25px;
    display: block;
    margin: auto;
    background-color: #23AE84;
    color: #FFFFFF;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.08px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .check-button {
      width: 75%;
      margin: auto;
    }
  }
</style>