import * as Api from "./Api";

export default {
    store(payload) {
        return Api.client.post(`/submitWzCaseDecision`, payload);
    },
    destroy(payload) {
        return Api.client.post(`/destroyWzCaseDecision`, payload);
    },
    createNewPaymentTransaction(payload) {
        return Api.client.post(`/createNewPaymentTransaction`, payload);
    },
    addWzCaseAttachments(payload) {
        return Api.client.post(`/addWzCaseAttachments`, payload);
    },
};