import WzCaseDecisionService from "@/services/WzCaseDecisionService";

export const namespaced = true;

const getDefaultState = () => {
    return {
        wz2_form: {
            case_guid: null,
            ip: null,
            decision: null,
            decision_message: null,
            order_number: null,
            with_parts_back: false,
            payment_type: null, //'cash_on_delivery'
            payment_operator : null, //'P24'
            immediate_consent: null,
            wz_costs_selected: null
        },

        payment_redirect_url: null,

        loading: false,
        error: false,
    }
}

export const state = getDefaultState();

export const mutations = {
    SET_WZ2_FORM(state, wz2_form) {
        state.wz2_form = wz2_form;
    },
    UPDATE_WZ2_FORM(state, payload) {
        state.wz2_form[payload.key] = payload.value;
    },
    SYNC_WZ2_FORM_FIELD_VALUES(state, payload) {
        if (state.wz2_form[payload.key].includes(payload.value)) {
            state.wz2_form[payload.key] = state.wz2_form[payload.key].filter(function (value) {
                if (value !== payload.value)
                    return value;
            });
        } else {
            state.wz2_form[payload.key].push(payload.value)
        }
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    setWz2Form({commit}, wz2_form) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        commit("SET_WZ2_FORM", wz2_form);
        commit("SET_LOADING", false);
    },
    updateWz2Form({commit}, payload) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        commit("UPDATE_WZ2_FORM", payload);
        commit("SET_LOADING", false);
    },
    syncValue({commit}, payload) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        commit("SYNC_WZ2_FORM_FIELD_VALUES", payload);
        commit("SET_LOADING", false);
    },
    storeWzCaseDecision({commit, state}) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        return WzCaseDecisionService.store({
            ...state.wz2_form
        }).then(() => {
                return true;
        }).catch(() => {
            commit("SET_ERROR", true);
            return false;
        }).finally(() => {
            setTimeout((function () {
                commit("SET_LOADING", false);
            }).bind(this), 600);
        });
    },
    uploadWzCaseAttachments({commit}, payload) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        return WzCaseDecisionService.addWzCaseAttachments(payload).then(() => {
            return true;
        }).catch(() => {
            commit("SET_ERROR", true);
            return false;
        }).finally(() => {
            setTimeout((function () {
                commit("SET_LOADING", false);
            }).bind(this), 600);
        });
    }
};

export const getters = {
    wz2_form: (state) => {
        return state.wz2_form;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};