<template>

  <div class="site" id="wz-case-form">
    <div id="header">
      <app-header/>
    </div>
    <div class="site-content">
      <router-view/>
    </div>
    <footer id="footer">
      <app-footer/>
    </footer>
  </div>

</template>


<script>

export default {
  name: "App",
}
</script>

<style>
#app {
  font-family: Nunito Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>