import WzCaseService from "@/services/WzCaseService";

export const namespaced = true;

const getDefaultState = () => {
    return {
        wz_case: {},
        wz_case_decision: {},
        payment_transaction: {},
        attachments: [],
        user_ip: null,
        loading: false,
        error: false,
    }
}

export const state = getDefaultState();

function setWzCase(commit, response) {
    commit("SET_WZ_CASE", response.data.wz_case ?? {});
    commit("SET_WZ_CASE_DECISION", response.data.wz_case_decision ?? {});
    commit("SET_PAYMENT_TRANSACTION", response.data.payment_transaction ?? {});
    commit("SET_ATTACHMENTS", response.data.attachments ?? []);
    commit("SET_USER_IP", response.data.ip ?? null);
    commit("SET_LOADING", false);
}

export const mutations = {
    SET_WZ_CASE(state, wz_case) {
        state.wz_case = wz_case;
    },
    SET_WZ_CASE_DECISION(state, wz_case_decision) {
        state.wz_case_decision = wz_case_decision;
    },
    SET_PAYMENT_TRANSACTION(state, payment_transaction) {
        state.payment_transaction = payment_transaction;
    },
    SET_ATTACHMENTS(state, attachments) {
        state.attachments = attachments;
    },
    SET_USER_IP(state, user_ip) {
        state.user_ip = user_ip;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

export const actions = {
    getWzCase({commit}, case_guid) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        return WzCaseService.show(case_guid)
            .then((response) => {
                setWzCase(commit, response);
                return true;
            }).catch(() => {
                commit("SET_ERROR", true);
                commit("SET_LOADING", false);
                return false;
            });
    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
};

export const getters = {
    wz_case: (state) => {
        return state.wz_case;
    },
    wz_case_decision: (state) => {
        return state.wz_case_decision;
    },
    payment_transaction: (state) => {
        return state.payment_transaction;
    },
    attachments: (state) => {
        return state.attachments;
    },
    pattern_email: (state) => {
        return state.pattern_email;
    },
    user_ip: (state) => {
        return state.user_ip;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};