<template>

  <div id="wz_case_form">
    <div v-if="error" class="container mt-4">
      <app-error/>
    </div>
    <div v-else>
      <app-loader v-if="loading"/>
      <div v-else>
        <div id="is-wz-case"
             v-if="wz_case.case_guid === $route.params.case_guid">
          <!-- SELECT WZ FORM  -->
          <app-wz1-form v-if="wz_case.wz_number === 'WZ1'"/>
          <app-wz2-form v-else-if="wz_case.wz_number === 'WZ2'"/>
          <div id="no-form-for-wz" v-else>
            <app-no-wz-case-found/>
          </div>
        </div>
        <div id="no-wz-case" v-else>
          <app-no-wz-case-found/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {mapGetters, mapActions} from 'vuex'

export default {
  name: "WzCaseForm",
  computed: {
    ...mapGetters('WzCase', {
      wz_case: 'wz_case',
      attachments: 'attachments',
      payment_transaction: 'payment_transaction',
      user_ip: 'user_ip',
      loading: 'loading',
      error: 'error'
    }),
  },
  methods: {
    ...mapActions('WzCase', {getWzCase: 'getWzCase', resetWzCaseState: 'resetState'}),
  },
  mounted() {
    /** Get WzCase from backend */
    this.getWzCase(this.$route.params.case_guid)
  },
  beforeUnmount() {
    this.resetWzCaseState();
  }
}
</script>

<style>

#app {
  font-family: Nunito Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>