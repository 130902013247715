<template>
  <div id="service-order-data" class="h-100">
    <div  class="card ccs-card-radius border-0 w-100 mb-0 pb-2">
      <div v-if="['AW', 'A', 'B'].includes(wz_case.pattern_email)">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title mulish ml-5 mb-0 pb-0 fw-bold">{{ $t('wz_case_form.service_order_data.add_documents') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div class="card-body">
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="fw-bolder subtitle">{{ $t('wz_case_form.service_order_data.make_scan') }}</div>
          </div>
          <div class="card-body col col-xxl-12 col-lg-12 order-xxl-2 order-md-3">
            <div class="input-file-field">
              <app-file_upload
                  v-on:getDroppedFiles="dropFiles($event);"
                  ref="customer_group_update_form"/>
            </div>
          </div>
          <div class="card-body subtitle mt-0 pt-0 mb-0 pb-0">
            <div class="fw-bolder" v-if="wz_case.pattern_email === 'B'">
              <p class="fs-4-ccs-special text-left text-sm-justify mt-3 mb-1">{{ $t('wz_case_form.wz2_form.documents_absence_one') }}</p>
            </div>
            <div class="fw-bolder" v-else>
              <p class="fs-4-ccs-special text-left text-sm-justify mt-3 mb-1">{{ $t('wz_case_form.wz2_form.documents_absence_many') }}</p>
            </div>
            <div class="card-body" >
              <div v-if="v_error" class="alert alert-danger" role="alert">
                {{ v_validation_error }}
              </div>
            </div>
            <div>
              <div class="form-check">
                <input v-model="params.decision_answer"
                       :value="this.$t('wz_case_form.wz2_form.free_send')"
                       id="decision_answer_1"
                       class="form-check-input decision_answer"
                       type="checkbox"
                       @change="checkedOnClick('decision_answer_1', 'decision_answer'); showButton = true;">
                <label class="form-check-label" for="decision_answer_1">{{ $t('wz_case_form.wz2_form.free_send') }}</label>
              </div>
              <div v-if="wz_case.pattern_email === 'AW'">
                <div class="form-check">
                  <input v-model="params.decision_answer"
                         id="decision_answer_2"
                         :value="this.$t('wz_case_form.wz2_form.paid_answer')"
                         class="form-check-input decision_answer"
                         type="checkbox"
                         @change="checkedOnClick('decision_answer_2', 'decision_answer'); showButton = true;">
                  <label class="form-check-label" for="decision_answer_2">{{ $t('wz_case_form.wz2_form.paid_answer') }}</label>
                </div>
              </div>
              <div v-if="wz_case.pattern_email === 'A'">
                <div class="form-check">
                  <input v-model="params.decision_answer"
                         id="decision_answer_3"
                         :value="this.$t('wz_case_form.wz2_form.repair_price_one')"
                         class="form-check-input decision_answer"
                         type="checkbox"
                         @change="checkedOnClick('decision_answer_3', 'decision_answer'); showButton = true;">
                  <label class="form-check-label" for="decision_answer_3">
                    {{ $t('wz_case_form.wz2_form.repair_price_one') }}
                  </label>
                  <span @click="this.additional_info = true">
                    <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="21px" viewBox="0 0 416.979 416.979" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85 c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786 c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576 c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765 c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"></path> </g> </g></svg>
                  </span>
                </div>
                <div v-if="this.additional_info" class="additional_info">
                  <b>{{ $t('wz_case_form.wz2_form.warning_start') }} </b>{{ $t('wz_case_form.wz2_form.repair_price_text_one') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="wz_case.pattern_email === 'C'">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title ml-5 mb-0 pb-0 fw-bold">{{ $t('wz_case_form.wz2_form.pattern_C_title') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div v-if="wz_case.fax_content.includes('IMEI')" class="card-body">
          <div class="card-body" >
            <div v-if="v_error" class="alert alert-danger" role="alert">
              {{ v_validation_error }}
            </div>
          </div>
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="col-xxl-3 col-lg-3 col-md-6 col-sm-12">
              <input v-model="params.imei" ref="imei" class="mb-3 input-group-text border-2" type="text" id="imei"
                     maxlength="15" :placeholder="this.$t('wz_case_form.wz2_form.label_imei')" @keyup="checkButton">
            </div>
          </div>
        </div>
        <div v-if="wz_case.fax_content.includes('S/N')" class="card-body">
          <div class="card-body pt-0 pb-0" >
            <div v-if="v_error" class="alert alert-danger" role="alert">
              {{ v_validation_error }}
            </div>
          </div>
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="col-xxl-3 col-lg-3 col-md-6 col-sm-12">
              <input v-model="params.sn" ref="sn" class="mb-3 input-group-text border-2" type="text" id="sn"
                     maxlength="20" :placeholder="this.$t('wz_case_form.wz2_form.label_sn')" @keyup="checkButton">
            </div>
          </div>
        </div>
      </div>

      <div v-if="wz_case.pattern_email === 'D'">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title ml-5 mb-0 pb-0 fw-bold">{{ $t('wz_case_form.wz2_form.pattern_D_title') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div class="card-body">
          <div class="card-body pt-0 pb-0" >
            <div v-if="v_error" class="alert alert-danger" role="alert">
              {{ v_validation_error }}
            </div>
          </div>
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
              <textarea v-model="params.fault_description" ref="fault_description" rows="4" class="textarea-field" @keyup="checkButton"
                        :placeholder="this.$t('wz_case_form.wz2_form.fault_description')" style="display: block" maxlength="500"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div v-if="wz_case.pattern_email === 'E'">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title mulish ml-5 mb-0 pb-0">{{ $t('wz_case_form.wz2_form.pattern_E_title') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div class="card-body">
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <p class="card-subtitle">{{ $t('wz_case_form.wz2_form.pattern_E') }}</p>
          </div>
          <div class="card-body" >
            <div v-if="v_error" class="alert alert-danger" role="alert">
              {{ v_validation_error }}
            </div>
          </div>
          <div class="card-body mt-0 pt-0 mb-0 pb-0">
            <div class="row">
              <div class="col-xxl-3 col-lg-3 col-md-12 col-sm-12">
                <div class="form-check mt-2">
                  <input v-model="params.complaint_type"
                         :value="this.$t('wz_case_form.wz2_form.select_E_option_1')"
                         id="complaint_type_1" checked="checked"
                         class="form-check-input complaint_type"
                         type="checkbox"
                         @change="checkedOnClick('complaint_type_1', 'complaint_type'); showButton = true;">
                  <label class="form-check-label" for="complaint_type_1">{{ $t('wz_case_form.wz2_form.select_E_option_1') }}</label>
                </div>
                <div class="form-check mt-2">
                  <input v-model="params.complaint_type"
                         :value="this.$t('wz_case_form.wz2_form.select_E_option_2')"
                         id="complaint_type_2"
                         class="form-check-input complaint_type"
                         type="checkbox"
                         @change="checkedOnClick('complaint_type_2', 'complaint_type'); showButton = true;">
                  <label class="form-check-label" for="complaint_type_2">{{ $t('wz_case_form.wz2_form.select_E_option_2') }}</label>
                </div>
                <div class="form-check mt-2">
                  <input v-model="params.complaint_type"
                         :value="this.$t('wz_case_form.wz2_form.select_E_option_3')"
                         id="complaint_type_3"
                         class="form-check-input complaint_type"
                         type="checkbox"
                         @change="checkedOnClick('complaint_type_3', 'complaint_type'); showButton = true;">
                  <label class="form-check-label" for="complaint_type_3">{{ $t('wz_case_form.wz2_form.select_E_option_3') }} &nbsp;
                    <span @click="this.additional_info = true">
                      <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 416.979 416.979" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85 c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786 c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576 c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765 c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"></path> </g> </g></svg>
                    </span>
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input v-model="params.complaint_type"
                         :value="this.$t('wz_case_form.wz2_form.select_E_option_4')"
                         id="complaint_type_4"
                         class="form-check-input complaint_type"
                         type="checkbox"
                         @change="checkedOnClick('complaint_type_4', 'complaint_type'); showButton = true;">
                  <label class="form-check-label" for="complaint_type_4">{{ $t('wz_case_form.wz2_form.select_E_option_4') }}</label>
                </div>
              </div>
              <div v-if="this.additional_info" class="col-xxl-9 col-lg-9 col-md-12 col-sm-12 additional_info p-2">
                <b>{{ $t('wz_case_form.wz2_form.warning_start') }}&nbsp; </b>{{ $t('wz_case_form.wz2_form.warning_E') }}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div v-if="wz_case.pattern_email === 'H'">
        <div class="card-body mt-3 mb-0 pb-0">
          <p class="card-title ml-5 mb-0 pb-0 fw-bold font-12">{{ $t('wz_case_form.wz2_form.pattern_H_title') }}</p>
        </div>
        <div class="card-body mb-0 pb-0">
          <p class="card-title ml-5 mb-0 pb-0 fw-bold">{{ $t('wz_case_form.wz2_form.pattern_H') }}</p>
        </div>
        <hr class="hr-ccs-thin">
        <div class="card-body">
          <div class="card-body pb-0 pt-0" >
            <div v-if="v_error" class="alert alert-danger" role="alert">
              {{ v_validation_error }}
            </div>
          </div>
          <div class="card-body mt-0 pt-0 mb-0 pb-0 d-flex">
            <div class="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-check mt-2">
                <input v-model="params.memory_info"
                       :value="this.$t('wz_case_form.wz2_form.pattern_H_checkbox_one')"
                       id="memory_info_1" checked="checked"
                       class="form-check-input memory_info"
                       type="checkbox"
                       @change="checkedOnClick('memory_info_1', 'memory_info'); showButton = true;">
                <label class="form-check-label" for="memory_info_1"><b>{{ $t('wz_case_form.wz2_form.pattern_H_checkbox_yes') }}</b>{{ $t('wz_case_form.wz2_form.pattern_H_checkbox_one') }}</label>
              </div>
              <div class="form-check mt-2">
                <input v-model="params.memory_info"
                       :value="this.$t('wz_case_form.wz2_form.pattern_H_checkbox_two')"
                       id="memory_info_2" checked="checked"
                       class="form-check-input memory_info"
                       type="checkbox"
                       @change="checkedOnClick('memory_info_2', 'memory_info'); showButton = true;">
                <label class="form-check-label" for="memory_info_2" disabled="disabled"><b>{{ $t('wz_case_form.wz2_form.pattern_H_checkbox_no') }}</b>{{ $t('wz_case_form.wz2_form.pattern_H_checkbox_two') }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body mt-4">
      <button v-if="showButton" class="send" @click="showConfirmation()"> {{ $t('wz_case_form.wz2_form.send') }}</button>
      <button v-else class="send disabled" disabled="disabled"> {{ $t('wz_case_form.wz2_form.send') }}</button>
    </div>
  </div>
  <app-confirmation-modal
      :message="confirm_message"
      ref="confirmationModal"
      @confirm="saveWz2Form"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Wz2FormProcess} from "@/mixins/Wz2FormProcess";
import {Wz1FormValidator} from "@/mixins/Wz1FormValidator";
import {Wz2FormValidator} from "@/mixins/Wz2FormValidator";
export default {
  name: "Wz2Details",
  data() {
    return {
      additional_info: false,
      params: this.getDefaultParams(),
      showButton: false,
      confirm_message: "Czy na pewno chcesz przesłać odpowiedź?"
    }
  },
  mixins: [Wz2FormProcess, Wz1FormValidator, Wz2FormValidator],
  created() {
    if (this.wz_case.wz_number === 'WZ2') {
      document.getElementById('footer').style.display = 'none';
    }
  },
  mounted() {
    this.params.current_form = this.wz_case.pattern_email;
  },
  computed: {
    ...mapGetters('Wz2Form', {wz2_form: 'wz2_form'}),
    ...mapGetters('WzCase', {wz_case: 'wz_case', wz_case_decision: 'wz_case_decision'}),
  },
  methods: {
    ...mapActions('Wz2Form', {updateWz2Form: 'updateWz2Form'}),
    getDefaultParams() {
      return {
        decision: '',
        complaint_type: '',
        imei: '',
        sn: '',
        files: [],
        fault_description: '',
        memory_info: '',
        decision_answer: '',
        current_form: ''
      }
    },
    showConfirmation() {
      this.$refs.confirmationModal.show();
    },
    checkedOnClick(el_id, class_name){
      let checkboxesList = document.getElementsByClassName(class_name);
      for (let i = 0; i < checkboxesList.length; i++) {
        checkboxesList.item(i).checked = false;
      }
      let checkedElement = document.getElementById(el_id);
      checkedElement.checked = true;
      this.writeDecision(checkedElement.value, class_name)
    },
    dropFiles(value) {
      this.params.files = value;
      if (this.params.files.length > 0) {
        this.showButton = true;
      }
    },
    writeDecision(value, class_name) {
      switch (class_name) {
        case 'complaint_type':
          this.params.decision = 'Zgłaszam reklamację z tytułu: ' + value;
          break;
        case 'decision_answer':
        case 'memory_info':
          this.params.decision = value;
          break;
      }
    },
    checkButton() {
      if (this.params.imei !== '' || this.params.sn !== '' || this.params.fault_description !== '') {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    }
  }
}
</script>

<style scoped>
  #additional-elements .form-switch input,
  #additional-elements .form-switch label {
    cursor: pointer;
  }
  #recommendations-table tr {
    margin-bottom: 10px;
  }
  #recommendations-table td {
    padding: 0.25rem 0.5rem;
    margin: 0;
  }
  #recommendations-table td p {
    margin: 0;
  }

  .ccs-card table {
    margin-bottom: 0.5rem!important;
  }

  .ccs-card .footer table {
    border-collapse: separate;
    border-spacing: 8px 0;
  }

  .ccs-card .footer table td {
    padding: 0!important;
  }

  @media (min-width: 576px) {
    .text-sm-justify {
      text-align: justify;
    }
  }

  .hr-ccs-thin {
    border: 0.01em solid #B4B4B4
  }
  .ml-5 {
    margin-left: 15px;
  }
  .input-group-text {
    width: 100%;
    background: none;
    border-radius: 10px;
  }
  .card-title {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    line-height: 25.1px;
    text-align: left;
    color: #4E4E4E;
  }
  .card-subtitle {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.57px;
    text-align: left;
    color: #4E4E4E;
  }
  .form-check-input {
    width: 15px;
    height: 15px;
  }
  .additional_info {
    margin-top: 10px;
    border-radius: 10px;
    background-color: #edeff1;
    align-content: center;
    justify-content: center;
    padding: 10px;
  }
  .textarea-field {
    border: 2px solid #D9D9D9;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    resize: none;
  }
  .textarea-field::-webkit-resizer{color: pink; }
  .disabled {
    opacity: 0.5;
  }
  .ccs-card-radius {
    box-shadow: 0 0 50px #ccc;
  }
  .form-check-label {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.08px;
    text-align: left;
    color: #4E4E4E;
  }
  @media (max-width: 768px) {
    .card-title {
      font-size: 14px;
      line-height: 18px;
    }
    .additional_info {
      font-size: 12px;
    }
    .font-12 {
      font-size: 12px;
    }
    .form-check {
      margin-bottom: 20px;
    }
    .subtitle {
      font-family: Mulish;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      text-align: left;

    }
    .send {
      width: 80%;
      height: 42px;
      border-radius: 20px;
      background-color: #23AE84;
      color: #ffffff;
      border: none;
      font-weight: bold;
      font-size: 14px;
      margin-left: 10%;
      margin-right: 10%;
    }
  }
  @media (min-width: 768px) {
    .subtitle {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 700;
      line-height: 23px;
      text-align: left;

    }
    .font-12 {
      font-size: 16px;
    }
    .send {
      width: 89px;
      height: 42px;
      border-radius: 20px;
      background-color: #23AE84;
      color: #ffffff;
      border: none;
      font-weight: bold;
      font-size: 14px;
      float: right;
    }
  }
  .form-check .form-check-input {
    margin-left: -2em;
  }
</style>