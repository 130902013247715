<template>

  <nav class="navbar navbar-main navbar-light bg-white shadow border-bottom-ccs">
    <div class="container-fluid">
      <a class="navbar-brand" target="_blank" href="https://www.ccsonline.pl/">
        <img src="../assets/CCS_logo_RGB_horizontal-color.png" alt="" width="263" height="63">
      </a>
    </div>
  </nav>

</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

.navbar-main {
  z-index: 1001;
}
.navbar-brand img {
  width: auto;
  height: 37px;
  margin-bottom: 20px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .navbar-brand img {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
</style>